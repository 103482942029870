"use client";

import React from "react";
import { cn } from "@/utils/classname";

import type { CustomRenderers } from "./serialize";
import { Serialize } from "./serialize";

export const RichText: React.FC<{
  className?: string;
  content: any;
  customRenderers?: CustomRenderers;
}> = ({ className, content, customRenderers }) => {
  if (!content) {
    return null;
  }

  return (
    <div className={cn("first:mt-0 last:mb-0", className)}>
      <Serialize content={content} customRenderers={customRenderers} />
    </div>
  );
};
