import { Avatar, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { MainNav } from '@/features/ui/components/main-nav';
import type { MainMenu, TopBar } from '@/payload-types';

import { LanguageDropdown } from './language-dropdown';
import { MobileMenu } from './mobile-menu';

export const SiteHeader: React.FC<{
  topBar: TopBar;
  mainMenu: MainMenu;
  openSearch: (value: boolean) => void;
}> = ({ mainMenu, topBar, openSearch }) => {
  return (
    <>
      {topBar?.announcement && typeof topBar?.announcement !== 'string' && (
        <header className=" top-0 z-50 bg-[#193CEB] px-4 py-3">
          <div className="mx-auto flex max-w-6xl flex-row items-center justify-center gap-x-2 lg:gap-x-4">
            <p className="text-sm font-semibold text-white">
              {topBar?.announcement?.content}
            </p>
            {topBar?.announcement?.buttonLink && (
              <a
                href={topBar?.announcement?.buttonLink}
                target="_blank"
                rel="noreferrer"
              >
                <Button className="min-h-10 ml-3 h-auto min-w-[100px] rounded-2xl border-2 border-white bg-transparent px-3 py-1 text-center text-xs font-semibold text-white">
                  {topBar?.announcement?.buttonText || 'Click here'}
                </Button>
              </a>
            )}
          </div>
        </header>
      )}
      <header className="sticky top-0 z-50 flex w-full flex-col items-center border-b border-b-slate-200 bg-white dark:border-b-slate-700 dark:bg-slate-950 sm:relative">
        <div className="mx-auto flex h-16 w-full max-w-6xl items-center px-4 sm:justify-between lg:h-24 lg:space-x-4 lg:px-4">
          <MainNav {...mainMenu} />
          <div className="flex flex-1 items-center justify-end space-x-4">
            <div className="flex items-center space-x-4">
              <div className="hidden md:block">
                <LanguageDropdown />
              </div>
              {/* <Button
                variant="ghost"
                className="uppercase"
                onClick={() => openSearch(true)}
              >
                <Avatar className="p-1">
                  <AvatarImage src={`/images/searchDark.png`} />
                </Avatar>
              </Button> */}
              <div className="md:hidden">
                <MobileMenu mainMenu={mainMenu} />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
