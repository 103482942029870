export const headerConfigs = {
  title: 'Tony Stark',
  navLinks: [
    {
      link: {
        type: 'custom',
        newTab: false,
        url: '/',
        label: 'home',
      },
    },
    {
      link: {
        type: 'custom',
        newTab: false,
        url: '/guides',
        label: 'guides',
      },
    },

    {
      link: {
        type: 'custom',
        newTab: false,
        url: '/calculators/compound-periodic',
        label: 'tools',
      },
    },
    {
      link: {
        type: 'custom',
        newTab: false,
        url: '/news',
        label: 'news',
      },
    },
    {
      link: {
        type: 'custom',
        newTab: false,
        url: '/modules/lp',
        label: 'kpb',
      },
    },
    // {
    //   link: {
    //     type: 'custom',
    //     newTab: false,
    //     url: '/events',
    //     label: 'events',
    //   },
    // },
    // {
    //   link: {
    //     type: 'custom',
    //     newTab: false,
    //     url: '/posts',
    //     label: 'students',
    //   },
    // },
  ],
};
