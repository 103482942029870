import React from "react";

import { VimeoPlayer } from "./vimeo";
import { YouTubePlayer } from "./youtube";

export const Video: React.FC<{
  platform?: "vimeo" | "youtube";
  id?: string;
}> = (props) => {
  const { platform = "vimeo", id } = props;

  return (
    <div
      className="width-100 relative mb-8 h-0 overflow-hidden bg-slate-50"
      style={{
        paddingTop: "56.25%",
      }}
    >
      {platform === "youtube" && <YouTubePlayer videoID={id} />}
      {platform === "vimeo" && <VimeoPlayer videoID={id} />}
    </div>
  );
};
