import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button } from '@/components/ui/button';
import { Icons } from '@/components/ui/icons';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTrigger,
} from '@/components/ui/sheet';
import { useClickOutside } from '@/hooks/useClickOutside';
import { useDisclosure } from '@/hooks/useDisclosure';
import type { MainMenu } from '@/payload-types';
import { cn } from '@/utils/classname';
import {
  BookOpenIcon,
  BookXIcon,
  CalculatorIcon,
  CalendarIcon,
  HomeIcon,
  LibraryIcon,
  Menu,
  NewspaperIcon,
  X,
} from 'lucide-react';
import { useTranslation } from 'next-i18next';

import { LanguageDropdown } from './language-dropdown';

export const MobileMenu: React.FC<{ mainMenu: MainMenu }> = ({ mainMenu }) => {
  const { locale } = useRouter();
  const [opened, handlers] = useDisclosure(false);
  const ref = useClickOutside(() => handlers.close());
  const { t } = useTranslation();

  return (
    <Sheet open={opened}>
      <SheetTrigger>
        <div
          className="inline-flex items-center justify-center rounded-full border-none px-3 font-semibold ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-800"
          onClick={() => {
            handlers.toggle();
          }}
        >
          <span className="sr-only">Menu</span>
          <Menu className="h-4 w-4" />
        </div>
      </SheetTrigger>
      <SheetContent
        side="left"
        ref={ref}
        closeContent={
          <div
            onClick={() => {
              handlers.close();
            }}
            className="bg-offWhite absolute right-4 top-3 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-gray-100 dark:ring-offset-gray-950 dark:focus:ring-gray-300 dark:data-[state=open]:bg-gray-800"
          >
            <Button
              variant="outline"
              className="border-none bg-white px-3 shadow-xl"
            >
              <X className="h-4 w-4 rounded-full text-slate-800" />
              <span className="sr-only">Close</span>
            </Button>
          </div>
        }
      >
        <SheetHeader>
          <div className="flex w-full flex-col items-center border-b border-b-slate-200 dark:border-b-slate-700 dark:bg-slate-950">
            <div className="flex h-16 w-full max-w-6xl items-center space-x-4  sm:justify-between sm:space-x-0">
              <div className="flex items-center gap-x-4">
                <Link
                  href="/"
                  locale={locale}
                  onClick={() => handlers.close()}
                  className="flex shrink-0 items-center"
                >
                  <Icons.Logo className="h-8 w-auto lg:h-12" />
                </Link>
              </div>
            </div>
          </div>
          <SheetDescription>
            <div className="gap-1 pt-4">
              <Link
                href="/"
                prefetch={false}
                locale={locale}
                onClick={() => {
                  handlers.close();
                }}
                className={cn(
                  'flex items-center py-4 text-lg text-slate-700 hover:text-slate-700 dark:text-neutral-100',
                  // item.link. && "cursor-not-allowed opacity-80"
                )}
              >
                <HomeIcon className="mr-4 text-slate-700" />
                {t('home')}
              </Link>
              <Link
                href="/guides"
                prefetch={false}
                locale={locale}
                onClick={() => {
                  handlers.close();
                }}
                className={cn(
                  'flex items-center py-4 text-lg text-slate-700 hover:text-slate-700 dark:text-neutral-100',
                  // item.link. && "cursor-not-allowed opacity-80"
                )}
              >
                <BookOpenIcon className="mr-4 text-slate-700" />
                {t('guides')}
              </Link>
              <Link
                href="/calculators/compound-periodic"
                prefetch={false}
                locale={locale}
                onClick={() => {
                  handlers.close();
                }}
                className={cn(
                  'flex items-center py-4 text-lg text-slate-700 hover:text-slate-700 dark:text-neutral-100',
                  // item.link. && "cursor-not-allowed opacity-80"
                )}
              >
                <CalculatorIcon className="mr-4 text-slate-700" />
                {t('tools')}
              </Link>
              <Link
                href="/news"
                prefetch={false}
                locale={locale}
                onClick={() => {
                  handlers.close();
                }}
                className={cn(
                  'flex items-center py-4 text-lg text-slate-700 hover:text-slate-700 dark:text-neutral-100',
                  // item.link. && "cursor-not-allowed opacity-80"
                )}
              >
                <NewspaperIcon className="mr-4 text-slate-700" />
                {t('news')}
              </Link>
              <Link
                href="/modules/lp"
                prefetch={false}
                locale={locale}
                onClick={() => {
                  handlers.close();
                }}
                className={cn(
                  'flex items-center py-4 text-lg text-slate-700 hover:text-slate-700 dark:text-neutral-100',
                  // item.link. && "cursor-not-allowed opacity-80"
                )}
              >
                <BookXIcon className="mr-4 text-slate-700" />
                {t('kpb')}
              </Link>
              {/* <Link
                href="/events"
                prefetch={false}
                locale={locale}
                onClick={() => {
                  handlers.close();
                }}
                className={cn(
                  "flex items-center py-4 text-lg text-slate-700 hover:text-slate-700 dark:text-neutral-100",
                  // item.link. && "cursor-not-allowed opacity-80"
                )}
              >
                <CalendarIcon className="mr-4 text-slate-700" />
                {t('events')}
              </Link> */}
            </div>
            <div className="gap-1 border-t pt-4">
              <div className="flex items-center py-3 text-sm font-medium text-neutral-600 hover:text-slate-700 dark:text-neutral-100">
                <LanguageDropdown />
              </div>
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};
