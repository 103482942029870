import path from 'path';
import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Icons } from '@/components/ui/icons';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from '@/components/ui/navigation-menu';
import { headerConfigs } from '@/configs/headerConfigs';
import type { MainMenu } from '@/payload-types';
import { cn } from '@/utils/classname';
import { useTranslation } from 'next-i18next';

export function MainNav(props: MainMenu) {
  const { locale } = useRouter();

  return (
    <div className="flex items-center gap-x-4">
      <Link href="/" locale={locale} className="flex shrink-0 items-center">
        <Icons.Logo className="h-8 w-auto lg:h-12" />
      </Link>
      <Navigation {...props} />
    </div>
  );
}

export default function Navigation(props: MainMenu) {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  return (
    <NavigationMenu className="hidden md:block">
      <NavigationMenuList>
        {headerConfigs.navLinks.map((item, index) => {
          return (
            <NavigationMenuItem key={index}>
              <Link
                href={`${item.link.url}`}
                className={cn(
                  'focus:bg-slate-10 text-brand-900 hover:text-brandAlt-950 block select-none space-y-1 rounded-md p-3 font-serif text-xl font-semibold leading-none no-underline outline-none transition-colors',
                  {
                    'font-normal text-slate-800': pathname === item.link.url,
                  },
                )}
              >
                {t(item.link.label)}
              </Link>
            </NavigationMenuItem>
          );
        })}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

// export default function Navigation(props: MainMenu) {
//   return (
//     <NavigationMenu className="hidden md:block">
//       <NavigationMenuList>
//         {props.navItems?.map((item, index) => {
//           return (
//             <NavigationMenuItem key={index}>
//               <CMSLink
//                 {...item.link}
//                 className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-slate-700 dark:focus:bg-slate-700"
//               >
//                 {/* <NavigationMenuLink className={navigationMenuTriggerStyle()}>
//                   {item.link.label}
//                 </NavigationMenuLink> */}
//               </CMSLink>
//             </NavigationMenuItem>
//           );
//         })}
//       </NavigationMenuList>
//     </NavigationMenu>
//   );
// }

const ListItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-slate-700 dark:focus:bg-slate-700',
            className,
          )}
          {...props}
        >
          <div className="text-brandAlt-950 text-lg font-bold leading-none">
            {title}
          </div>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = 'ListItem';

// return item.subNav ? (
//   <NavigationMenuItem key={index}>
//     <NavigationMenuTrigger>{item.link.label}</NavigationMenuTrigger>
//     <NavigationMenuContent>
//       <ul className="grid gap-3 gap-x-4 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
//         <li className="flex h-full flex-col justify-between pr-4">
//           <div>
//             <p variant={"large"}>About us</p>
//             <p variant={"subtle"} className="mt-1">
//               {item.link.description}
//             </p>
//           </div>
//           <NavigationMenuLink asChild>
//             <a
//               href={item.link.url}
//               className={cn(buttonVariants(), "mt-4 w-fit")}
//             >
//               Go to section
//               <ArrowRightCircle className="ml-2 h-4 w-4" />
//             </a>
//           </NavigationMenuLink>
//         </li>
//         <div className="grid grid-cols-2 gap-x-4 gap-y-2">
//           {item.subNav.map((item, index) => (
//             <ListItem
//               href={item.href}
//               title={item.title}
//               key={index}
//             />
//           ))}
//         </div>
//       </ul>
//     </NavigationMenuContent>
//   </NavigationMenuItem>
// ) : (
