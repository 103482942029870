import React from 'react';
import { RichText } from '@/components/rich-text';
import type { ReusableContent } from '@/payload-types';
import { useTranslation } from 'next-i18next';

type Props = Extract<ReusableContent['layout'][0], { blockType: 'cta' }>;

export const FooterCallToAction: React.FC<Props> = ({ ctaFields }) => {
  const { t } = useTranslation();
  const { title, richText, belowButton } = ctaFields;
  return (
    <div>
      <div className="mx-auto my-12 mb-20 max-w-6xl px-4">
        <div className="mx-auto flex max-w-2xl flex-col items-center justify-between lg:max-w-none lg:flex-row">
          <div className="text-center text-3xl font-bold leading-snug tracking-tight text-white sm:text-5xl sm:leading-snug lg:max-w-xl lg:text-left">
            <h2 className="inline  font-serif sm:block lg:inline xl:block">
              {title}
            </h2>
            {richText && (
              <RichText
                content={richText}
                className="prose lg:prose-xl mt-4 max-w-4xl text-white"
              />
            )}
          </div>
          <form
            className="mx-auto mt-10 w-full max-w-md lg:mt-0"
            target="_blank"
            action="https://mail.asnbacademy.com.my/subscribe"
            method="POST"
            acceptCharset="utf-8"
          >
            <div className="flex gap-x-4">
              <label htmlFor="email" id="email" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="focus:ring-brandAlt-500 min-w-0  flex-auto rounded-xl border-0 bg-[#001A37]  px-4 py-4 text-white placeholder-white  focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                placeholder={t('enterYourEmail')}
              />
              <input
                type="hidden"
                name="list"
                value="b53gya2gNzTw2763ogRL8wEg"
              />
              <input type="hidden" name="subform" value="yes" />
              <button
                type="submit"
                className="focus-visible:outline-brandAlt-500 flex-none rounded-xl bg-[#A5FE7E] px-3.5 py-2.5 text-sm font-semibold text-[#193CEB]  hover:bg-[#A5FE7E]/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                {t('subscribe')}
              </button>
            </div>
            <p className="mt-4 text-sm leading-6 text-gray-300">
              {belowButton}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
