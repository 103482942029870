import React, { useEffect } from 'react';
import type { StaticImageData } from 'next/image';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { cn } from '@/utils/classname';

import type { Props } from './types';

// const { breakpoints } = cssVariables
const breakpoints = {
  s: 768,
  m: 1024,
  l: 1440,
};

export const Image: React.FC<Props> = (props) => {
  const {
    imgClassName,
    onClick,
    onLoad: onLoadFromProps,
    sizes: sizesFromProps,
    resource,
    priority,
    fill,
    src: srcFromProps,
    alt: altFromProps,
    width: widthFromProps,
    height: heightFromProps,
  } = props;

  const [isLoading, setIsLoading] = React.useState(true);
  const router = useRouter();

  const isModulesPage = router.pathname.includes('/modules/content');

  let width: number | undefined = widthFromProps;
  let height: number | undefined = heightFromProps;
  let alt = altFromProps;
  let src: StaticImageData | string | undefined = srcFromProps;

  if (!src && resource && typeof resource !== 'string') {
    width = resource.width;
    height = resource.height;
    alt = resource.alt;
    src = `${process.env.NEXT_PUBLIC_CMS_URL}/media/${resource.filename}`;

    src = resource.url ? resource.url : src;
  }

  // NOTE: this is used by the browser to determine which image to download at different screen sizes
  const sizes =
    sizesFromProps ||
    Object.entries(breakpoints)
      .map(([, value]) => `(max-width: ${value}px) ${value}px`)
      .join(', ');

  // console.log('isModulesPage', isModulesPage);

  const baseClasses = [
    isLoading && 'bg-slate-50',
    'w-full h-full object-cover max-h-[640px]',
    imgClassName,
  ]
    .filter(Boolean)
    .join(' ');

  const customModuleClasses = [
    isLoading && 'bg-slate-50',
    'h-auto max-w-[100%] max-h-[77vh] object-contain not-prose',
    imgClassName,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <NextImage
        className={cn(
          isModulesPage ? customModuleClasses : baseClasses,
          'aspect-video',
        )}
        unoptimized
        src={src || ''}
        alt={alt || ''}
        onClick={onClick}
        onLoad={() => {
          setIsLoading(false);
          if (typeof onLoadFromProps === 'function') {
            onLoadFromProps();
          }
        }}
        fill={fill}
        width={!fill ? width : undefined}
        height={!fill ? height : undefined}
        sizes={sizes}
        priority={priority}
      />
    </>
  );
};
