import React from "react";

export const VimeoPlayer: React.FC<{
  videoID?: string;
}> = ({ videoID }) => {
  return (
    <iframe
      title="Vimeo player"
      className="absolute -left-[2px] -top-[2px] h-[100%+4px] w-[100%+4px]"
      src={`https://player.vimeo.com/video/${videoID}}`}
      frameBorder="0"
      allowFullScreen
      allow="autoplay; fullscreen; picture-in-picture"
    />
  );
};
