import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Icons } from '@/components/ui/icons';
import { Separator } from '@/components/ui/separator';
import { footerConfigs } from '@/configs/footerConfigs';
import type { Category, Footer, PostCollection } from '@/payload-types';
import { useTranslation } from 'next-i18next';

import { FooterCallToAction } from './site-footer-cta';

export const SiteFooter: React.FC<{
  footer: Footer;
  categories: Category[];
  postCollections: PostCollection[];
  className: string;
}> = ({ footer, categories, postCollections, className }) => {
  const { t, i18n } = useTranslation('common');
  const router = useRouter();

  const isModulesPage = router.pathname.includes('/modules');

  return (
    <>
      <footer
        className={`bg-brand-900 w-full pb-24 lg:pb-32 ${className}`}
        aria-labelledby="footer-heading"
      >
        {isModulesPage ? (
          <div className="mx-auto flex max-w-6xl flex-row gap-4 bg-[#0057B7] px-4 py-8">
            <div className="align-items-center flex gap-2">
              <div>
                <img
                  className="m-auto h-[24px] w-[24px]"
                  src={'/images/kid.png'}
                  alt={t('modulePage.footer.suitableAllAges')}
                  width={24}
                />
              </div>
              <div className="text-white">
                {t('modulePage.footer.suitableAllAges')}
              </div>
            </div>
            <Separator orientation="vertical" className="mx-5 h-auto" />
            <div className="align-items-center flex gap-2">
              <div>
                <img
                  className="m-auto h-[24px] w-[24px]"
                  src={'/images/graphup.png'}
                  alt={t('modulePage.footer.buildFinancialLiteracy')}
                  width={24}
                />
              </div>
              <div className="text-white">
                {t('modulePage.footer.buildFinancialLiteracy')}
              </div>
            </div>
            <Separator orientation="vertical" className="mx-6 h-auto" />
            <div className="align-items-center flex gap-2">
              <div>
                <img
                  className="m-auto h-[24px] w-[24px]"
                  src={'/images/pen.png'}
                  alt={t('modulePage.footer.numberFullModules')}
                  width={24}
                />
              </div>
              <div className="text-white">
                {t('modulePage.footer.numberFullModules')}
              </div>
            </div>
          </div>
        ) : null}
        <div className="border-b border-white/30 bg-[#193CEB] pt-12">
          <FooterCallToAction
            ctaFields={{
              title: t('homePage.cta.title'),
              belowButton: t('homePage.cta.belowButton'),
            }}
          />
        </div>
        {/* {router.pathname === "/" && (
          <div className="border-b border-white/30 bg-[#1A237E]">
            <FooterCallToAction
              ctaFields={{
                title: t("homePage.cta.title"),
                belowButton: t("homePage.cta.belowButton"),
              }}
            />
          </div>
        )} */}
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-6xl px-4 lg:px-4">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="mt-12 lg:mt-16 xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="grid grid-flow-col gap-8 lg:grid-cols-3 xl:col-span-2">
                <div>
                  <Link href="/" className="flex shrink-0 items-center">
                    <Icons.LogoInvert className="h-6 w-auto lg:h-8" />
                  </Link>
                  <div className="col-span-2 xl:col-span-1">
                    <div className="mt-10 first:mt-0 md:mt-0">
                      <ul className="mt-6 space-y-2 lg:space-y-4">
                        {footerConfigs?.navLinks.map((item, index) => (
                          <li key={index}>
                            <Link
                              href={`${item.link.url}`}
                              className="text-brand-50 hover:text-brand-200 leading-6 transition-all duration-300"
                            >
                              {t(item.link.label)}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {categories && (
                  <div className="hidden sm:block">
                    <h3 className="text-lg font-bold text-white">
                      {t('categories')}
                    </h3>
                    <div className="col-span-2 xl:col-span-1">
                      <div className="mt-10 first:mt-0 md:mt-0">
                        <ul className="mt-6 space-y-2 lg:space-y-4">
                          {categories &&
                            [...categories]
                              .sort((a, b) => a.order - b.order)
                              .map((category) => (
                                <li key={category.id}>
                                  <Link
                                    href={{
                                      pathname: '/posts',
                                      query: { category: category.id },
                                    }}
                                    locale={i18n.language}
                                    className="text-brand-50 hover:text-brand-200 leading-6 transition-all duration-300"
                                  >
                                    {category.title}
                                  </Link>
                                </li>
                              ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {postCollections && (
                  <div className="hidden sm:block">
                    <h3 className="text-lg font-bold text-white">
                      {t('lifeStages')}
                    </h3>
                    <div className="col-span-2 xl:col-span-1">
                      <div className="mt-10 first:mt-0 md:mt-0">
                        <ul className="mt-6 space-y-2 lg:space-y-4">
                          {/* <JsonView data={postCollections} /> */}
                          {[...postCollections]
                            .sort((a, b) => a.order - b.order)
                            .map((postCollection) => (
                              <li key={postCollection.id}>
                                <Link
                                  href={`/collections/${postCollection.slug}`}
                                  className="text-brand-50 hover:text-brand-200 leading-6 transition-all duration-300"
                                  locale={i18n.language}
                                >
                                  {postCollection.title}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                {/* {footer?.navItems && footer?.navItems?.length > 0 && (
                <div className="hidden sm:block">
                  <h3 className="text-lg font-bold text-white">{t("links")}</h3>
                  <div className="col-span-2 xl:col-span-1">
                    <div className="mt-10 first:mt-0 md:mt-0">
                      <ul className="mt-6 space-y-2 lg:space-y-4">
                        {footer.navItems &&
                          footer.navItems?.map((item, index) => {
                            return (
                              <li key={index}>
                                <CMSLink
                                  {...item.link}
                                  className="text-brand-50 hover:text-brand-200 leading-6 transition-all duration-300"
                                />
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              )} */}
              </div>

              <div className="mb-24 mt-14 space-y-8 xl:mt-0 xl:pl-12">
                {/* <Link href="/" className="flex shrink-0 items-center">
                  <Icons.LogoInvert className="h-6 w-auto lg:h-8" />
                </Link> */}
                <div className="mt-4">
                  <Link
                    href={`/terms-of-use`}
                    className="text-brand-50 hover:text-brand-200 leading-6 underline transition-all duration-300"
                    locale={i18n.language}
                  >
                    {t('termsOfUse')}
                  </Link>
                </div>
                <p className="text-brand-50 leading-5">
                  {t('footer.allRightsReserved')} &copy;{' '}
                  {new Date().getFullYear()}
                </p>
                <div className="flex space-x-6">
                  {footerConfigs.social.map((item) => (
                    <a
                      target="_blank"
                      key={item.name}
                      href={item.href}
                      className="text-brand-500 hover:text-brand-400 transition-all duration-300"
                    >
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-7 w-7" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default SiteFooter;
