import { useMemo } from "react";
import { useRouter } from "next/router";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "next-i18next";
import { locales } from "numeral";

function capitalize(lang: string) {
  return lang.slice(0, 1).toUpperCase() + lang.slice(1);
}

export function LanguageDropdown() {
  const router = useRouter();
  const { i18n } = useTranslation();
  const { language: currentLanguage } = i18n;
  const locales = ['en', 'ms']

  const languageNames = useMemo(() => {
    return new Intl.DisplayNames([currentLanguage], {
      type: "language",
    });
  }, [currentLanguage]);

  const onToggleLanguageClick = async (locale: string) => {
    const { pathname, query, asPath } = router;
    console.log('Selected locale', locale)
    router.push({ pathname, query }, asPath, { locale: locale });
    i18n.changeLanguage(locale)
  };


  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="text-brandAlt-950 uppercase">
          <Avatar className="mr-3">
            <AvatarImage
              src={
                currentLanguage
                  ? `/flags/${currentLanguage}.png`
                  : `/flags/ms.png`
              }
            />
          </Avatar>
          {currentLanguage === "ms" ? "bm" : currentLanguage}{" "}
          <ChevronDown className="text-brandAlt-950 ml-1" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" forceMount>
        {locales
          .filter((x) => x !== "default")
          .map((locale) => (
            <DropdownMenuItem
              className="group flex items-center py-2"
              // defaultValue={locale}
              onClick={() => onToggleLanguageClick(locale)}
              key={locale}
            >
              <Avatar className="mr-3">
                <AvatarImage src={`/flags/${locale}.png`} />
              </Avatar>
              <p className="text-brandAlt-950 group-hover:text-brandAlt-800 uppercase">
                {locale === "ms" ? "BM" : locale}
                {/* {capitalize(languageNames.of(locale) ?? locale)} */}
              </p>
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
