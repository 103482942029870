import Head from "next/head";
import { useRouter } from "next/router";
import { fqdn, siteConfigs } from "@/configs/siteConfigs";
import { DefaultSeo, LogoJsonLd, NextSeo, OrganizationJsonLd } from "next-seo";

const DefaultMeta = () => {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
        <link
          rel="apple-touch-icon"
          href={`${router.basePath}/apple-touch-icon.png`}
          key="apple"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${router.basePath}/favicon-32x32.png`}
          key="icon32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${router.basePath}/favicon-16x16.png`}
          key="icon16"
        />
        <link
          rel="icon"
          href={`${router.basePath}/favicon.ico`}
          key="favicon"
        />
      </Head>
      <DefaultSeo
        {...siteConfigs}
        openGraph={{
          type: "website",
          siteName: "ASNB Academy",
          images: [
            {
              url: `${fqdn}/logo.jpg`,
              width: 512,
              height: 512,
              alt: "ASNB Academy",
            },
          ],
          url: siteConfigs.canonical,
          locale: router.locale,
        }}
      />
      <LogoJsonLd logo={`${fqdn}/logo.jpg`} url={fqdn} />
      <OrganizationJsonLd
        type="Corporation"
        id="https://www.asnb.com.my/asnbv2_0index.php"
        logo="https://www.asnb.com.my/img/logo-asnb-fit.png"
        legalName="Amanah Saham Nasional Berhad (197901003200 (47457-V))"
        name="Amanah Saham Nasional Berhad"
        address={{
          streetAddress: "UG, Balai PNB, 201-A",
          addressLocality: "Jalan Tun Razak",
          addressRegion: "KL",
          postalCode: "50400",
          addressCountry: "MY",
        }}
        contactPoint={[
          {
            telephone: "+603 2057 3000",
            contactType: "customer service",
            email: "asnbcare@pnb.com.my",
            contactOption: "TollFree",
            availableLanguage: "English",
          },
        ]}
        sameAs={["https://asnb.com.my/"]}
        url={fqdn}
      />
    </>
  );
};

export { DefaultMeta };
