import { DefaultSeoProps } from "next-seo";

export const fqdn: string =
  process.env.NEXT_PUBLIC_APP_URL ?? "http://localhost:3000";


export const siteConfigs: DefaultSeoProps = {
  title: "ASNB Academy",
  titleTemplate: `%s | ASNB Academy`,
  themeColor: "#0056bc",
  defaultTitle: "ASNB Academy",
  description: "",
  canonical: fqdn,
  twitter: {
    handle: "@asnbmy_official",
    site: "@asnbmy_official",
    cardType: "summary_large_image",
  },
};
