import '@/styles/global.css';
import '@/styles/nprogress.css';

import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import {
  Merriweather,
  Montserrat,
  Nunito_Sans,
  Space_Grotesk,
} from 'next/font/google';
import { useRouter } from 'next/router';
import { TailwindIndicator } from '@/components/tailwind-indicator';
import { Dialog, DialogContentWithoutClose } from '@/components/ui/dialog';
import { DefaultMeta } from '@/features/seo/default-meta';
import GcseSearch from '@/features/ui/components/gcse-search';
import SiteFooter from '@/features/ui/components/site-footer';
import { SiteHeader } from '@/features/ui/components/site-header';
import { useApollo } from '@/graphql';
import { usePreserveScroll } from '@/hooks/usePreserveScroll';
import type {
  Category,
  Footer,
  MainMenu,
  PostCollection,
  TopBar,
} from '@/payload-types';
import { ApolloProvider } from '@apollo/client';
import { TrackingHeadScript } from '@phntms/next-gtm';
import { motion } from 'framer-motion';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';
import NProgress from 'nprogress';

if (process.env.APP_ENV !== 'production') {
  import('react-json-view-lite/dist/index.css');
}

NProgress.configure({ showSpinner: false });

const spaceGrotesk = Space_Grotesk({
  weight: ['400'],
  subsets: ['latin'],
  variable: '--font-spaceGrotesk',
  display: 'swap',
});

const montserratMedium = Montserrat({
  weight: ['500'],
  subsets: ['latin'],
  variable: '--font-montserrat-medium',
  display: 'swap',
});

const montserratBold = Montserrat({
  weight: ['800'],
  subsets: ['latin'],
  variable: '--font-montserrat-bold',
  display: 'swap',
});

const nunitoSans = Nunito_Sans({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  variable: '--font-nunito-sans',
  display: 'swap',
});

const merriweather = Merriweather({
  weight: ['400', '700', '900'],
  subsets: ['latin'],
  variable: '--font-merriweather',
  display: 'swap',
});

const MyApp = (
  appProps: AppProps<{
    id?: string;
    preview?: boolean;
    collection?: string;
    topBar: TopBar;
    mainMenu: MainMenu;
    footer: Footer;
    postCollections: PostCollection[];
    categories: Category[];
  }>,
) => {
  const { Component, pageProps } = appProps;
  const { footer, mainMenu, topBar, postCollections, categories } = pageProps;

  const router = useRouter();
  const apolloClient = useApollo(pageProps);
  usePreserveScroll();

  // remove footer's margin top in tools page
  const isToolsPage = router.pathname.includes('/calculators');
  const isModulesPage = router.pathname.includes('/modules/content');
  const footerMarginStyle = isToolsPage ? '' : 'mt-32';

  const [isSearching, setIsSearching] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeComplete', () => NProgress.done());
    router.events.on('routeChangeError', () => NProgress.done());

    const storedIsDialogOpen = localStorage.getItem('isDialogOpen');
    if (storedIsDialogOpen) {
      setIsSearching(true);
    }

    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      if (isSearching) {
        localStorage.setItem('isDialogOpen', 'true');
      } else {
        localStorage.removeItem('isDialogOpen');
      }
    }
  }, [isSearching]);

  return (
    // <AuthProvider>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider defaultTheme="light" attribute="class">
        <style jsx global>
          {`
            :root {
              --font-spaceGrotesk: ${spaceGrotesk.style.fontFamily};
              --font-montserrat-medium: ${montserratMedium.style.fontFamily};
              --font-montserrat-bold: ${montserratBold.style.fontFamily};
              --font-nunito-sans: ${nunitoSans.style.fontFamily};
              --font-merriweather: ${merriweather.style.fontFamily};
            }
          `}
        </style>
        <DefaultMeta />
        <TrackingHeadScript id="GTM-KJNVN8MN" isGTM={true} />

        {isModulesPage ? null : (
          <SiteHeader
            topBar={topBar}
            mainMenu={mainMenu}
            openSearch={setIsSearching}
          />
        )}
        {/* <div>
          <Dialog open={isSearching} onOpenChange={setIsSearching}>
            <DialogContentWithoutClose className="google-parent-container max-h-[80%] min-h-[80%] min-w-[80%] overflow-auto">
              <GcseSearch openDialog={setIsSearching} />
            </DialogContentWithoutClose>
          </Dialog>
        </div>  */}

        <motion.div
          key={router.route}
          initial="pageInitial"
          animate="pageAnimate"
          variants={{
            pageInitial: {
              opacity: 0,
            },
            pageAnimate: {
              opacity: 1,
            },
          }}
        >
          <Component {...pageProps} />
        </motion.div>
        {isModulesPage ? null : (
          <SiteFooter
            className={footerMarginStyle}
            footer={footer}
            categories={categories}
            postCollections={postCollections}
          />
        )}
        <TailwindIndicator />
      </ThemeProvider>
    </ApolloProvider>
    // </AuthProvider>
  );
};

export default appWithTranslation(MyApp);
