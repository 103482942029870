import { useMemo } from "react";
import type { NormalizedCacheObject } from "@apollo/client";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

async function loggingFetch(
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> {
  const body = JSON.parse((init?.body as string) ?? "{}");

  const start = Date.now();
  console.log(
    `${new Date().toISOString().slice(-13)} 📡 Sending ${
      body.operationName
    } request`,
  );
  // console.log(body);
  const response = await fetch(input, init);
  console.log(
    `${new Date().toISOString().slice(-13)} 📡 Received ${
      body.operationName
    } response in ${Date.now() - start}ms`,
  );

  return {
    ...response,

    async text() {
      const start = Date.now();
      const result = await response.text();
      console.log(
        `${new Date().toISOString().slice(-13)} ⚙️  Read ${
          body.operationName
        } response body in ${Date.now() - start}ms (${result.length} bytes)`,
      );
      // console.log(JSON.stringify(JSON.parse(result), null, 0));
      return result;
    },
  };
}

const createApolloClient = () => {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: new HttpLink({
      fetch: loggingFetch,
      uri: `${process.env.NEXT_PUBLIC_CMS_URL}/api/graphql`, // Server URL (must be absolute)
      credentials: "same-origin", // Additional fetch() options like `credentials` or `headers`
    }),
    cache: new InMemoryCache({
      // typePolicies is not required to use Apollo with Next.js - only for doing pagination.
      typePolicies: {
        Query: {
          fields: {
            posts: relayStylePagination(),
          },
        },
      },
    }),
  });
};

export const initializeApollo = (
  initialState: NormalizedCacheObject | null = null,
) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any,
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export const useApollo = (pageProps: any) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
};
